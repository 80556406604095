/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    annotation: "annotation",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Vamos a pasar a ver los conceptos claves de la teoría de control moderna, estos son la realimentación y el error."), "\n", React.createElement(_components.h3, null, "El Error"), "\n", React.createElement(_components.p, null, "Cuando estamos controlando, intentamos que nuestra señal de salida (y) sea lo más parecida posible a la referencia (r) en todo momento, por eso se define la señal de error (e) como la diferencia entre ambas:"), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "e"), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "r"), React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "y")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "e=r-y")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.43056em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.66666em",
      verticalAlign: "-0.08333em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  }), React.createElement(_components.span, {
    className: "mbin"
  }, "−"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2222222222222222em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.625em",
      verticalAlign: "-0.19444em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "y")))))), "\n", React.createElement(_components.p, null, "Podemos representar esta resta en diagrama de bloques de la siguiente forma:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 348px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/cd3dd60e2c55ab2b9b7c91058c571fb2/34ea4/restador.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 73.41772151898735%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVQoz6VTUQqDMAzdtb2BnkiPoJ+iv2JrWa2gKIglqE3Glp+xOZ3b+0qTvuTxml7quq6qSp2BEGIYBs/zLkSEiHQG67oSURAEd7JzDs9gWRYi8n3/42RObpZ48h6ZFe3IfiW3bQsAHDdNUxTFOI58lFL2fc83N2QjotZaKaW1DsMwz3MiStNUKXV9II7jLMuIaJ7nj7Kdc9ZaIUSSJF3XrQ9wnoNtw567SCmjKNp05Ngwa60x5kcyAJRlaYx59/yYjIgAME3TwVPtbBh3Ob1hX+32P7/qBmSHU5W7tRlrAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Resta de señales referencia y salida.\"\n        title=\"Resta de señales referencia y salida.\"\n        src=\"/static/cd3dd60e2c55ab2b9b7c91058c571fb2/34ea4/restador.png\"\n        srcset=\"/static/cd3dd60e2c55ab2b9b7c91058c571fb2/c26ae/restador.png 158w,\n/static/cd3dd60e2c55ab2b9b7c91058c571fb2/6bdcf/restador.png 315w,\n/static/cd3dd60e2c55ab2b9b7c91058c571fb2/34ea4/restador.png 348w\"\n        sizes=\"(max-width: 348px) 100vw, 348px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "]"), "\n", React.createElement(_components.p, null, "El signo de negativo indica que es una resta, si no lo pusiéramos tendríamos la representación de una suma."), "\n", React.createElement(_components.p, null, "Para afianzar el concepto vamos a ver una representación de una señal continua cualquiera de salida respecto al tiempo, la referencia y lo que sería el error en el momento actual:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 508px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/2142ff07b890408d3b5ff36408d3ac52/2fd48/error.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 70.25316455696203%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVQoz5WS63KCMBCFef838wU6wygWlAoISTapJkDCng5gLW3V2m/2R7KzZ/YyJ4rj2BhDRFprKaX6D1FZlgDatj2dTgCYGU8THQ4HAEKIPM8BDMPATxMVRQHAOUeaRjEPDH4Q3zrPYmutUurR2FPa9/6G+GzPmco0tGSpoJZBoLItm74hUNVXA4YbnStV9fAdux6t4zHa8eECQlqkL8lLQGi5XU7+JaZp7M6x73AtmLd4N6Z4K34vdT2YbRolBIRgKVHX48TO4Xxm77Hd1nUt7oq7zm42yrlLQQiwdtQLEbLMWDvnL/wUa23X65vXZubwh0mIXBznxpAQcsHoQSKazWiM2e/3x+Px6qVo/ix9M0yECT/RT3jvpZRu2u3SebVaZVmWJMnrgvQOu90uTdPtJx/38Sg+VUUChgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Ejemplo de medida del error en un punto.\"\n        title=\"Ejemplo de medida del error en un punto.\"\n        src=\"/static/2142ff07b890408d3b5ff36408d3ac52/2fd48/error.png\"\n        srcset=\"/static/2142ff07b890408d3b5ff36408d3ac52/c26ae/error.png 158w,\n/static/2142ff07b890408d3b5ff36408d3ac52/6bdcf/error.png 315w,\n/static/2142ff07b890408d3b5ff36408d3ac52/2fd48/error.png 508w\"\n        sizes=\"(max-width: 508px) 100vw, 508px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  }), "]"), "\n", React.createElement(_components.p, null, "Una vez obtenida esta señal podemos hacer uso de ella para el control y ahí es de donde surge el concepto de realimentación."), "\n", React.createElement(_components.h3, null, "La Realimentación"), "\n", React.createElement(_components.p, null, "La idea de la realimentación consiste en utilizar esta señal de error para alimentar un controlador que intentará reducir este error al mínimo. Nuestro esquema quedará de la siguiente forma:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/8c557/Realimentaci%C3%B3n.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.139240506329116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVQY042Q0W6GIAyFff9nJHohJoYWRJGGIthlf/dvLrvZd9UcenpKh2VZiCildD7IOdObv0rOWcUhxigi933Lg+u6+EUppfcuIr13ftNa07Zh2zYRaa313mutzCwiiHgcBxEhYs5ZRI7jCCEQUYxRLfd9f5qJyDk3TdOyLADgnDPGAIC1dp5n7z0ijuMYQpjnOcYIAMaYUspXsoiEEKy1WmsgM4cQvpNjjMx8nicApJR+1tZ/ruuaUiIi7z0AIKJzTvff910VRPTe/zLrwWqt5UV9oGdj5qei/YNzrrWmb9e/0SkfbsTL1rtrxj4AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Esquema de Realimentación.\"\n        title=\"Esquema de Realimentación.\"\n        src=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/f058b/Realimentaci%C3%B3n.png\"\n        srcset=\"/static/dcdb2fc3ae33bfcbb7329791f732d607/c26ae/Realimentaci%C3%B3n.png 158w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/6bdcf/Realimentaci%C3%B3n.png 315w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/f058b/Realimentaci%C3%B3n.png 630w,\n/static/dcdb2fc3ae33bfcbb7329791f732d607/8c557/Realimentaci%C3%B3n.png 700w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Llegados a este punto ya hemos aprendido en que se basa el control, a partir de ahora vamos a ver como controlar y dentro de poco tendremos actividades prácticas, para aprender “haciendo”, que es la única forma de aprender."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
